<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="methods" href="/tabs/methods">
          <!-- <ion-icon :icon="square" /> -->
          <ion-label><span class="yaser">اليسير</span><span class="men"> من </span><span class="alzad">الزاد</span></ion-label>
        </ion-tab-button>

        <ion-tab-button tab="surah" href="/tabs/surah">
          <!-- <ion-icon :icon="ellipse" /> -->
          <ion-label>سورة الملك</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="prayers" href="/tabs/prayers">
          <!-- <ion-icon :icon="triangle" /> -->
          <ion-label>أدعية مختصرة</ion-label>
        </ion-tab-button>

      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script >
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  //IonIcon,
  IonPage,
} from "@ionic/vue";

export default {
  name: "Tabs",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    //IonIcon,
    IonPage,
  },
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.yaser{
color:#e69e2c;
}
.men{
color:white;
font-size:8px;
}
.alzad{
color:#00b4d8;

}


ion-tab-bar {
  --background: var(--my-tabs-background);
  font-family: "Almarai";
  border-top:2px solid  var(--my-tabs-background);
}
ion-tab-button {
  --background: none;

  --color: var(--my-tabs-text-color);
}

.tab-selected {
  background: var(--my-tabs-selected-background) ;
  color: var(--my-tabs-selected-text-color) !important;
}

</style>