import { createRouter, createWebHashHistory  } from '@ionic/vue-router';

import Tabs from '../views/Tabs.vue'

const routes = [
  {
    path: '/',
    redirect: '/tabs/prayers'
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/prayers'
      },
      {
        path: 'prayers',
        component: () => import('@/views/prayers.vue')
      },
      {
        path: 'surah',
        component: () => import('@/views/surah.vue')
      },
      {
        path: 'methods',
        component: () => import('@/views/methods.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
